<template>
  <div
    class="mdc-checkbox mdc-data-table__row-checkbox table-checkbox"
    :style="checkboxOutline"
  >
    <input
      type="checkbox"
      class="mdc-checkbox__native-control"
      :class="extraClass"
      :id="id"
      v-model="localIsChecked"
    />
    <div
      class="mdc-checkbox__background"
      :style="`--mdc-theme-secondary:${displayColor}; border-color:${displayColor}`"
    >
      <svg
        class="mdc-checkbox__checkmark"
        viewBox="0 0 24 24"
        :style="svgStyle"
      >
        <path
          class="mdc-checkbox__checkmark-path"
          d="M1.73,12.91 8.1,19.28 22.79,4.59"
        />
      </svg>
      <div class="mdc-checkbox__mixedmark"></div>
    </div>
    <div class="mdc-checkbox__ripple"></div>
  </div>
  <slot></slot>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { isThemeLight, themeColors } from '@/common/shared.js'

export default {
  emits: ['checked'],
  props: {
    extraClass: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    isChecked: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore()

    const checkboxInput = ref(null)

    const checkboxOutline = computed(() => {
      return {
        outlineColor: isThemeLight[store.getters.currentThemeName] ? '#000' : '#fff',
      }
    })

    const displayColor = computed(() => store.getters.checkboxColor)

    const localIsChecked = ref(props.isChecked)

    watch(localIsChecked, () => {
      emit('checked', localIsChecked.value)
    })

    const svgStyle = computed(() => {
      return {
        fill: localIsChecked.value ? displayColor.value : themeColors[store.getters.currentThemeName].modalBackgroundColor,
      }
    })

    return {
      checkboxInput,
      checkboxOutline,
      displayColor,
      localIsChecked,
      svgStyle,
    }
  },
}
</script>

<style lang="scss" scoped>
@use "./node_modules/@material/checkbox";
@include checkbox.core-styles;

.table-checkbox {
  transform: scale(1.2);
  vertical-align: middle;

  &:hover,
  &:focus-within {
    transform: scale(1.44);
  }
}

.table-checkbox:focus-within {
  outline: 2px solid #000;
  outline-offset: -12px;
}
</style>
